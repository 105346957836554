<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title="$vuetify.lang.t('$vuetify.currency')" />

    <v-card-text class="pb-0">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid>
          <v-row>
            <!-- Currency Name -->
            <v-col class="py-0" cols="12">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.name')"
                v-model="myObj.name"
                :rules="generalRule"
              />
            </v-col>

            <!-- Currency Symbol -->
            <v-col class="py-0" cols="12">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.CurrencySymbol')"
                v-model="myObj.code"
                :rules="generalRule"
              />
            </v-col>

            <!-- Exchange Rate -->
            <v-col class="py-0" cols="12">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.exchangeRate')"
                v-model.number="myObj.rate"
                type="number"
                :rules="generalRule"
              />
            </v-col>

            <!-- date -->
            <v-col class="py-0" cols="12">
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                min-width="auto"
                class="ma-0 pa-0"
              >
                <template v-slot:activator="{ on, attrs }" class="ma-0 pa-0">
                  <v-text-field
                    v-model="myObj.date"
                    :rules="generalRule"
                    append-icon="mdi-calendar"
                    :label="$vuetify.lang.t('$vuetify.date')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>

                <v-date-picker v-model="myObj.date" class="ma-0 pa-0" />
              </v-menu>
            </v-col>
          </v-row>

          <!-- Remark -->
          <v-row>
            <v-col class="pb-0" cols="12" lg="12" md="12" sm="12">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.note')"
                v-model="myObj.note"
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-8">
      <v-spacer />

      <v-btn color="red darken-2" outlined @click="cancelForm">
        {{ $vuetify.lang.t("$vuetify.cancel") }}
      </v-btn>

      <v-btn color="green darken-2" @click="validate">
        {{ $vuetify.lang.t("$vuetify.save") }}
      </v-btn>

      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
export default {
  name: "CurrencyCRUD",
  props: { dialog: Boolean, myObj: Object },
  data() {
    return {
      valid: false,
      generalRule: [(v) => !!v || "this field is required"],
    };
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit("handledata", this.myObj);
        this.$refs.form.resetValidation();
      } else {
        console.log("false");
      }
    },
    cancelForm() {
      this.$emit("update:dialog", false);
      this.$refs.form.resetValidation();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>